export const data = function() {
  return {
    isDebug: true,
    loading: false,
    vendorID: 0,
    vendor: null,
    tabs: [
      { name: 'vendorContacts', label: 'Contacts' },
      { name: 'vendorInsuranceCoverages', label: 'Insurance Coverage' },
      { name: 'vendorLicenses', label: 'Licences' },
      { name: 'vendorServiceTypes', label: 'Service Types' },
      { name: 'vendorWorkOrders', label: 'Work Orders' },
      { name: 'vendorInvoices', label: 'Invoices' }
    ],
    styles: {
      mainContainer: {
        minHeight: '1000px'
      }
    },
    transitionName: 'slide-down',
    showUploadModal: false
  }
}
