var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContentLayoutOnly',{attrs:{"role":"region","vendor-details":""}},[(_vm.vendor)?_c('section',{staticClass:"section card"},[_c('div',{staticClass:"level pl-5 pr-5",style:({ display: 'flex' })},[_c('div',{staticClass:"pt-5 level-left level-item title"},[_vm._v(_vm._s(_vm.$t('vendors.detailsTitle')))]),_c('div',{staticClass:"level-right",style:({ display: 'flex' })},[_c('svg',{attrs:{"width":"30","height":"30","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 19l-7-7 7-7"}})]),_c('b-button',{attrs:{"label":"Back to Vendors"},on:{"click":function($event){$event.preventDefault();return _vm.returnToParent()}}})],1)]),(
        _vm.vendor.remittanceVendorGlobalAddress &&
          _vm.vendor.remittanceVendorGlobalAddress != undefined &&
          _vm.vendor.remittanceVendorGlobalAddress.address &&
          _vm.vendor.remittanceVendorGlobalAddress.address != undefined
      )?_c('div',[_c('Address',{attrs:{"address":_vm.vendor.remittanceVendorGlobalAddress.address}})],1):_vm._e(),_c('LinksMenu',{style:({
        paddingLeft: '20px'
      }),attrs:{"aria-label":"vendor details tabs","tabs":_vm.tabs,"prefix":"vendor.","params":{
        id: _vm.$route.params.id
      }}},[_c('section',{style:({ overflowX: 'auto', flexGrow: 2 })},[_c('SlideTransition',{attrs:{"name":_vm.transitionName}},[_c('keep-alive',[_c('router-view',{key:_vm.$route.name})],1)],1)],1)])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }