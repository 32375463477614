import { vendorList } from '@/services/Vendors/store'

export const methods = {
  async reload() {
    this.loading = true

    this.vendorID = this.$route.params.id || 0

    await vendorList
      .dispatch('getVendorById', {
        vendorID: this.vendorID
      })
      .then(({ result }) => {
        this.vendor = result
      })

    if (this.isDebug == true) console.debug('vendorID=' + this.vendorID)

    this.loading = false
  },

  returnToParent() {
    this.$router.push({
      name: 'vendors'
    })
  }
}
