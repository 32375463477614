var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px',
    margin: '0px'
  })},[(_vm.address)?_c('div',{staticClass:"card-content address-card",attrs:{"role":"region","tabindex":"0","aria-label":"property address or addresses"}},[_c('h5',{attrs:{"tabindex":"0"}},[_vm._v(_vm._s(_vm.address.addressOne || ''))]),(_vm.address.addressTwo)?_c('h6',{attrs:{"tabindex":"0"}},[_vm._v(" "+_vm._s(_vm.address.addressTwo || '')+" ")]):_vm._e(),(_vm.address.addressThree)?_c('h6',{attrs:{"tabindex":"0"}},[_vm._v(" "+_vm._s(_vm.address.addressThree || '')+" ")]):_vm._e(),(_vm.address.addressFour)?_c('h6',{attrs:{"tabindex":"0"}},[_vm._v(" "+_vm._s(_vm.address.addressFour || '')+" ")]):_vm._e(),(_vm.address.addressFive)?_c('h6',{attrs:{"tabindex":"0"}},[_vm._v(" "+_vm._s(_vm.address.addressFive || '')+" ")]):_vm._e(),_c('h6',{attrs:{"tabindex":"0"}},[_vm._v(" "+_vm._s(_vm.address.city || '')+", "+_vm._s(_vm.address.state || '')+" "+_vm._s(_vm.address.postalCode || '')+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }